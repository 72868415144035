export default [
  {
    title: 'Dashboards',
    icon: 'HomeIcon',
    route: 'dashboard-ecommerce',
  },
  {
    header: 'Products',
    icon: 'PackageIcon',
    children: [
      {
        title: 'Products',
        route: 'apps-e-commerce-shop',  //change it
        icon: 'ListIcon',
      },
      {
        title: 'Add Product',
        route: 'products-add',
        icon: 'PlusCircleIcon',
      },
    ],
  },
  {
    title: 'Statistics',
    icon: 'ActivityIcon',
    route: 'statistics',
  },
  {
    header: 'Orders',
    icon: 'ShoppingBagIcon',
    children: [
      {
        title: 'List',
        route: 'orders',  //change it
        icon: 'ListIcon',
      },
      {
        title: 'Logs',
        route: 'orders-logs',
        icon: 'AlignLeftIcon',
      },
    ],
  },
  {
    title: 'Campagins',
    icon: 'GlobeIcon',
    route: 'campagins',
  },
  {
    title: 'Warehouses',
    icon: 'HomeIcon',
    route: 'campagins',
  },
  {
    header: 'Administration',
    icon: 'ZapIcon',
    children: [
      {
        title: 'Countries',
        route: 'admin-countries',
        icon: 'GlobeIcon',
      },
      {
        title: 'API Tokens',
        route: 'admin-apitokens',
        icon: 'Share2Icon',
      },
      {
        title: 'Logs',
        route: 'admin-logs',
        icon: 'AlignLeftIcon',
      },
      {
        title: 'Servers',
        icon: 'ServerIcon',
        children: [
          {
            title: 'Servers',
            route: 'admin-servers',
          },
          {
            title: 'Commands',
            route: { name: 'admin-server-commands' },
          }
        ],
      },
      {
        title: 'Domains',
        icon: 'GlobeIcon',
        children: [
          {
            title: 'Domain List',
            route: 'admin-servers',
          },
          {
            title: 'Add Domain',
            route: { name: 'admin-server-commands' },
          }
        ],
      },
      {
        title: 'User',
        icon: 'UserIcon',
        children: [
          {
            title: 'List',
            route: 'admin-users-list',
          },
          {
            title: 'Add User',
            route: { name: 'admin-user-add'},
          },
          {
            title: 'Roles & Permissions',
            route: { name: 'admin-roles'},
          },
          {
            title: 'Banned IPs',
            route: { name: 'admin-banned'},
          },
        ],
      },
      {
        title: 'Dev Tools',
        icon: 'CodepenIcon',
        children: [
          {
            title: 'Dev Logs',
            route: 'admin-developer-logs',
          },
          {
            title: 'System Activity',
            route: { name: 'admin-developer-system-activity' },
          },
          {
            title: 'System Update',
            route: 'admin-developer-system-update',
          },
          {
            title: 'Devices',
            route: 'admin-developer-devices',
          },
          {
            title: 'IP Addresses',
            route: 'admin-developer-ips',
          },
          {
            title: 'Login Attempts',
            route: 'admin-developer-loginattempts',
          },
          {
            title: 'Auth Tokens',
            route: 'admin-developer-tokens',
          },
        ],
      },
    ],
  },
]